﻿/*
 Here we need to save all urns.
 */

/*
    Base urn.
 */
const BASE_URN = "urn:merchantgrowth:"

/*
    Get when tried call verify number with duplicated checkout id and session id. 
 */
export const SESSION_REUSED = BASE_URN + "session-reused"

