import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { phoneVerificationState } from "../../recoil/phone-verification/PhoneVerificationFormState";
import {
  DialogContent,
  Typography,
  Box,
  Avatar,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFingerprint, faHourglass } from "@fortawesome/free-solid-svg-icons";
import VerificationInput from "react-verification-input";
import PhoneInput from "./PhoneInput";
import Countdown from "../../components/countdown";
import SnackbarAlert from "../../components/snackbar-alert";
import Smartlook from "smartlook-client";
import { clearLocalStorageScoringData, track } from "../../utils";
import { usePrefill } from "../../hooks/prefill-hook";
import { customerState } from "../../recoil/customer/CustomerState";
import { useStepRedirect } from "../../hooks/redirect-to-step-hook";
import { client } from "../../api/tabit-api";
import { sessionInfoState } from "../../recoil/session-info/session-info-state";
import { qualificationResponseState } from "../../recoil/qualification-response/QualificationResponseState";

const PhoneVerification = () => {
  const [activeStep, setActiveStep] = useState(0);
  const phone = useRecoilValue(phoneVerificationState);
  const [time, setTime] = useState({ minutes: 3, seconds: 0 });
  const [codeSentCounter, setCodeSentCounter] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const setCustomer = useSetRecoilState(customerState);
  const theme = useTheme();
  const { t } = useTranslation("phoneVerification");
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const setData = usePrefill();
  const redirectToStep = useStepRedirect();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const setQualificationResponse = useSetRecoilState(
    qualificationResponseState
  );
  const sessionState = useRecoilValue(sessionInfoState);

  const handleTryAgain = async () => {
    setErrorMsg("");
    try {
      const { data } = await client.CodeVerification_SendCode(null, {
        phoneNumber: phone?.phone.replace(/\D+/g, "").substring(1),
        countryCode: "1",
        requestType: "sms",
        emailAddress: undefined,
      });
      if (data.isValid) {
        track("Try again clicked");
        setCodeSentCounter(codeSentCounter + 1);
        setTime({ minutes: 3, seconds: 0 });
      } else {
        const errorMsg = data.errors && data.errors[0].message;
        setErrorMsg(errorMsg || "");
      }
    } catch (error) {
      setErrorMsg(t("errorRequest"));
    }
  };

  const handleBack = () => {
    track("Wrong phone number clicked");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setTime({ minutes: 3, seconds: 0 });
    setCodeSentCounter(0);
  };

  const handleLookup = async () => {
    setErrorMsg("");
    try {
      const { data } = await client.Lookup_GetAccountDetails(
        phone?.phone.replace(/\D+/g, "").substring(1) || ""
      );
      if (data.customerId) {
        const response = await client.Customer_GetCustomer(data.customerId);
        setCustomer({ customerId: data.customerId });
        setData(response.data);
        switch (data.currentStep) {
          case "QualifyCompleted":
            if (sessionState.requestSource === "TabitDirectApp") {
              const applyResponse = await client.Customer_ApplyForCheckout(
                data.customerId,
                { cartTotal: sessionState.cartTotal }
              );

              const status = await client.Customer_GetQualificationStatus(
                data.customerId
              );
              setQualificationResponse(status.data);

              const { eligibilityStatus } = applyResponse.data;
              switch (eligibilityStatus) {
                case "RemoveItemsFromCartRequired":
                  redirectToStep("RemoveItemsFromCart");
                  break;
                case "ManualReviewRequired":
                  redirectToStep("ManualReview");
                  break;
                case "Rejected":
                  redirectToStep("Rejected");
                  break;
                case "Approved":
                  redirectToStep("QualifyCompleted");
                  break;
                default:
                  redirectToStep("ApplicationError");
                  break;
              }
            } else {
              redirectToStep(data.currentStep || "Personal");
            }
            break;
          default:
            redirectToStep(data.currentStep || "Personal");
            break;
        }
      } else {
        const { data } = await client.Customer_CreateCustomer();
        setCustomer({ customerId: data });
        redirectToStep("Personal");
      }
      return true;
    } catch (error: any) {
      setErrorMsg(t("errorRequest"));
    }
  };

  const handleVerification = async (value: string) => {
    if (!isNaN(+value) && !(time.minutes === 0 && time.seconds === 0)) {
      setErrorMsg("");
      setLoading(true);
      setVerificationCode(value);
      try {
        const { data } = await client.CodeVerification_VerifyCode(null, {
          phoneNumber: phone?.phone.replace(/\D+/g, "").substring(1),
          countryCode: "1",
          verificationCode: value,
          requestType: "sms",
        });
        if (data.isValid) {
          await handleLookup();
          const phoneNumber =
            phone?.phone.replace(/\D+/g, "").substring(1) || "";
          setLoading(false);
          Smartlook.identify(phoneNumber, {
            appMode: sessionState.requestSource,
            cartTotal: sessionState.cartTotal,
            sessionId: sessionState.sessionToken || "",
            appVersion: import.meta.env.VITE_VERSION,
          });
          clearLocalStorageScoringData();
          track("Code is verified");
        } else {
          setErrorMsg(t("errorInvalidCode"));
          setVerificationCode("");
        }
      } catch (error: any) {
        setErrorMsg(t("errorInvalidCode"));
        setLoading(false);
      }
    }
  };

  return loading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "325px",
      }}
    >
      <CircularProgress />
      <SnackbarAlert errorMsg={errorMsg} />
    </Box>
  ) : (
    <DialogContent>
      <Box display="flex" alignItems="center" marginBottom="10px">
        <Avatar sx={{ bgcolor: "#4AB5D8", marginRight: "10px" }}>
          <FontAwesomeIcon icon={faFingerprint} size="lg" color="#13436D" />
        </Avatar>
        <Typography
          variant="h6"
          component="div"
          gutterBottom
          color="secondary"
          sx={{ fontWeight: 600, margin: 0 }}
        >
          {t("title")}
        </Typography>
      </Box>
      <Typography
        variant={desktop ? "body1" : "body2"}
        gutterBottom
        sx={{ fontWeight: 600, marginTop: "10px" }}
      >
        {t("description")}
      </Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>{t("stepOneLabel")}</StepLabel>
          <StepContent>
            <Typography variant="body2">{t("stepOneDescription")}</Typography>
            <PhoneInput handleNext={handleNext} />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{t("stepTwoLabel")}</StepLabel>
          <StepContent>
            <Typography variant="body2">
              {t("stepTwoDescription")}{" "}
              <Box
                component="span"
                data-sl="mask"
                sx={{
                  color: "#4ab5d8",
                  fontWeight: 600,
                }}
              >
                *******{phone?.phone.slice(-4)}.
              </Box>
            </Typography>
            <Box
              className={`custom-styles ${
                (errorMsg === t("errorInvalidCode") &&
                  !isNaN(+verificationCode)) ||
                (time.minutes === 0 && time.seconds === 0)
                  ? "expired"
                  : ""
              }`}
            >
              <VerificationInput
                classNames={{
                  character: "character",
                  characterSelected: "character--selected",
                }}
                onComplete={(value) => handleVerification(value)}
                autoFocus
                validChars="0-9"
                inputProps={{ inputMode: "numeric" }}
              />
            </Box>
            <Box textAlign="center">
              {codeSentCounter > 2 ? (
                <Typography
                  variant="body2"
                  sx={{
                    color: "#ACACAC",
                    fontWeight: 600,
                  }}
                >
                  {t("tryAgainTime")}{" "}
                </Typography>
              ) : (
                <Typography variant="body2">
                  {t("didntReceive")}{" "}
                  <Box
                    component="span"
                    sx={{
                      color: "#4ab5d8",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                    onClick={handleTryAgain}
                  >
                    {t("tryAgain")}
                  </Box>
                </Typography>
              )}
              <Typography variant="body2">
                {t("wrongNumber")}{" "}
                <Box
                  component="span"
                  sx={{ color: "#4ab5d8", fontWeight: 600, cursor: "pointer" }}
                  onClick={handleBack}
                >
                  {t("edit")}
                </Box>
              </Typography>
              <FontAwesomeIcon
                icon={faHourglass}
                color="#4ab5d8"
                spin
                enableBackground="white"
              />
              <Typography component="span" variant="body2" sx={{ ml: 1 }}>
                {t("expiresIn")} <Countdown time={time} setTime={setTime} />{" "}
                {t("minutes")}
              </Typography>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
      <SnackbarAlert errorMsg={errorMsg} />
    </DialogContent>
  );
};

export default PhoneVerification;
