import { useSetRecoilState } from "recoil";
import { personalInfoState } from "../recoil/personal-info/PersonalInfoFormState";
import { personalGoogleAutocompleteState } from "../recoil/personal-info/PersonalGoogleAutocompleteState";
import { businessInfoState } from "../recoil/business-info/BusinessInfoFormState";
import { businessGoogleAutocompleteState } from "../recoil/business-info/BusinessGoogleAutocompleteState";
import { timeInBusinessState } from "../recoil/business-info/TimeInBusinessState";
import { personalAdressState } from "../recoil/personal-info/PersonalAddressState";
import { businessAdressState } from "../recoil/business-info/BusinessAddressState";
import { flinksState } from "../recoil/flinks/FlinksState";
import { Components } from "../types/openapi";

export const usePrefill = () => {
  const setPersonal = useSetRecoilState(personalInfoState);
  const setPersonalGoogleAutocomplete = useSetRecoilState(
    personalGoogleAutocompleteState
  );
  const setPersonalAddress = useSetRecoilState(personalAdressState);
  const setBusiness = useSetRecoilState(businessInfoState);
  const setBusinessGoogleAutocomplete = useSetRecoilState(
    businessGoogleAutocompleteState
  );
  const setBusinesslAddress = useSetRecoilState(businessAdressState);
  const setTimeInBusiness = useSetRecoilState(timeInBusinessState);
  const setFlinksState = useSetRecoilState(flinksState);

  const setData = (data: Components.Schemas.CustomerDto) => {
    if (data.personalInformation) {
      const {
        firstName,
        lastName,
        dateOfBirth,
        emailAddress,
        address,
        acceptedTerms,
      } = data.personalInformation;
      const { street, city, state, postalCode, country } = address;
      setPersonal({
        first: firstName || "",
        last: lastName || "",
        dob:
          new Date(dateOfBirth || "")
            .toLocaleString("en-US", { timeZone: "UTC" })
            .split(/\D/)
            .slice(0, 3)
            .map((num) => num.padStart(2, "0"))
            .join("/") || "",
        email: emailAddress || "",
        terms: acceptedTerms || false,
        street: street || "",
        city: city || "",
        province: state || "",
        postal: postalCode || "",
        country: country || "",
      });

      setPersonalGoogleAutocomplete({
        description: `${street}, ${city}, ${state}, ${country}`,
        structured_formatting: {
          main_text: "",
          main_text_matched_substrings: [],
          secondary_text: "",
        },
      });
      setPersonalAddress({
        street: street || "",
        city: city || "",
        province: state || "",
        postal: postalCode || "",
        country: country || "",
      });
    }
    if (data.businessInformation) {
      const {
        legalBusinessName,
        tradeName,
        entityTypeCode,
        subIndustryCode,
        timeInBusiness,
        address,
      } = data.businessInformation;
      const { street, city, state, postalCode, country } = address;
      setBusiness({
        legal: legalBusinessName || "",
        businessAs: tradeName || "",
        entity: entityTypeCode || "",
        industry: subIndustryCode || "",
        timeInBusiness: timeInBusiness || 0,
        street: street || "",
        city: city || "",
        province: state || "",
        postal: postalCode || "",
        country: country || "",
      });
      setBusinessGoogleAutocomplete({
        description: `${street}, ${city}, ${state}, ${country}`,
        structured_formatting: {
          main_text: "",
          main_text_matched_substrings: [],
          secondary_text: "",
        },
      });
      setBusinesslAddress({
        street: street || "",
        city: city || "",
        province: state || "",
        postal: postalCode || "",
        country: country || "",
      });
      if (timeInBusiness) {
        const timeToSave: number =
          timeInBusiness > 1
            ? timeInBusiness + 11
            : Math.round(timeInBusiness * 12);
        setTimeInBusiness(timeToSave);
      }
      if (data.bankingInformation) {
        const { flinksLoginId, flinksAccountId } = data.bankingInformation;
        setFlinksState({
          loginId: flinksLoginId,
          accountId: flinksAccountId,
        });
      }
    }
  };

  return setData;
};
