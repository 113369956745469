import { useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { useRecoilState } from "recoil";
import { phoneVerificationState } from "../../recoil/phone-verification/PhoneVerificationFormState";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../../components/form-input";
import SnackbarAlert from "../../components/snackbar-alert";
import { track } from "../../utils";
import { client } from "../../api/tabit-api";
import axios from "axios";
import { SESSION_REUSED } from "../../constants/urn-error-types";

type HandleNext = {
  handleNext: () => void;
};

const PhoneInput = ({ handleNext }: HandleNext) => {
  const [phone, setPhone] = useRecoilState(phoneVerificationState);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { t } = useTranslation("phoneVerification");
  const validationSchema = object({
    phone: string()
      .nonempty(t("errorPhoneRequired"))
      .regex(
        /^(\+\d{1,2}\s)?\(?[2-9]\d{2}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        t("errorInvalidNumber")
      ),
  });

  type RegisterInput = TypeOf<typeof validationSchema>;
  const methods = useForm<RegisterInput>({
    resolver: zodResolver(validationSchema),
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful, phone]);

  const onSubmitHandler: SubmitHandler<RegisterInput> = async (values) => {
    try {
      setErrorMsg("");
      setLoading(true);
      const { data } = await client.CodeVerification_SendCode(null, {
        phoneNumber: values?.phone.replace(/\D+/g, "").substring(1),
        countryCode: "1",
        requestType: "sms",
        emailAddress: undefined,
      });
      if (data.isValid) {
        setLoading(false);
        track("Verification code sent");
        handleNext();
        setPhone(values);
      } else {
        const errorMsg = data.errors && data.errors[0].message;
        setErrorMsg(errorMsg || "");
        setLoading(false);
      }
    } catch (error) {
      if(axios.isAxiosError(error) && error.response?.data.type == SESSION_REUSED)
      {
        setErrorMsg(t("sessionIdAndCheckoutIdAreExpired"));
      }
      else 
      {
        setErrorMsg(t("errorRequest"));
      }
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <FormInput
          name="phone"
          value={phone?.phone}
          required
          fullWidth
          label={t("inputLabel")}
          type="text"
          sx={{ my: 2 }}
          autoFocus
        />
        <Box sx={{ position: "relative" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ color: "white" }}
            disabled={loading}
            type="submit"
            endIcon={
              <FontAwesomeIcon icon={faPaperPlane} size="lg" color="white" />
            }
          >
            {t("send")}
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "0%",
                marginTop: "-12px",
                marginLeft: "55px",
              }}
            />
          )}
        </Box>
        <SnackbarAlert errorMsg={errorMsg} />
      </Box>
    </FormProvider>
  );
};

export default PhoneInput;
