import { useState, useEffect, useMemo } from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import { FormInputProps } from "../form-input/form-input-props";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { personalAdressState } from "../../recoil/personal-info/PersonalAddressState";
import { businessAdressState } from "../../recoil/business-info/BusinessAddressState";
import { personalGoogleAutocompleteState } from "../../recoil/personal-info/PersonalGoogleAutocompleteState";
import { businessGoogleAutocompleteState } from "../../recoil/business-info/BusinessGoogleAutocompleteState";
import { GoogleAutocompleteAddressProps } from "./google-autocomplete-props";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "@googlemaps/js-api-loader";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const autocompleteService = { current: null };
const placeDetailsService = { current: null };
const sessionToken = { current: null };

export default function GoogleMaps({
  error,
  helperText,
  addressType,
  register,
}: FormInputProps) {
  const setAddress = useSetRecoilState(
    addressType === "business" ? businessAdressState : personalAdressState
  );
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [value, setValue] = useRecoilState(
    addressType === "business"
      ? businessGoogleAutocompleteState
      : personalGoogleAutocompleteState
  );
  const [inputValue, setInputValue] = useState("");
  const regex = new RegExp(
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
  );
  const [options, setOptions] = useState<
    readonly GoogleAutocompleteAddressProps[]
  >([]);
  const { t } = useTranslation("personalInfo");
  const loader = new Loader({
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    libraries: ["places"],
    language: "en",
  });
  loader.load();

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: {
            input: string;
            sessionToken: string | null;
            componentRestrictions: object;
            types: string[];
          },
          callback: (
            results?: readonly GoogleAutocompleteAddressProps[]
          ) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        import.meta.env.VITE_GOOGLE_AUTOCOMPLETE_THROTTLE
      ),
    []
  );

  const getProvinceAbbreviation = (provinceName: string) => {
    switch (provinceName) {
      case "Alberta":
        return "AB";
      case "British Columbia":
        return "BC";
      case "Manitoba":
        return "MB";
      case "New Brunswick":
        return "NB";
      case "Newfoundland and Labrador":
        return "NL";
      case "Nova Scotia":
        return "NS";
      case "Ontario":
      case "Ont":
        return "ON";
      case "Prince Edward Island":
        return "PE";
      case "Quebec":
        return "QC";
      case "Saskatchewan":
        return "SK";
      case "Yukon":
        return "YK";
      case "Northwest Territories":
        return "NT";
      case "Nunavut":
        return "NU";
      default:
        return provinceName;
    }
  };

  const getPostalCode = async (placeId: string | undefined) => {
    try {
      const postalRes: any = await new Promise((resolve, reject) => {
        if (!placeId) reject("placeId not provided");
        try {
          (placeDetailsService.current as any).getDetails(
            {
              placeId,
              fields: ["address_components"],
              sessionToken: sessionToken.current,
            },
            (details: any) => {
              return resolve(details?.address_components);
            }
          );
        } catch (e) {
          reject(e);
        }
      });
      const postalArr =
        postalRes?.filter((el: any) => el.types.includes("postal_code")) ||
        null;
      const postal =
        postalArr.length &&
        postalArr[0].long_name &&
        regex.test(postalArr[0].long_name)
          ? postalArr[0].long_name
          : "";
      return postal;
    } catch (error) {
      console.log(error);
    }
  };

  const handleAutoFill = (e: any) => {
    if (e.animationName === "mui-auto-fill" && focus) setOpen(false);
    if (e.animationName === "mui-auto-fill-cancel" && focus) setOpen(true);
  };

  useEffect(() => {
    let active = true;
    if (
      !autocompleteService.current &&
      !placeDetailsService.current &&
      (window as any).google
    ) {
      sessionToken.current = new (
        window as any
      ).google.maps.places.AutocompleteSessionToken();
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
      const map = new (window as any).google.maps.Map(
        document.createElement("div")
      );
      placeDetailsService.current = new (
        window as any
      ).google.maps.places.PlacesService(map);
    }
    if (!autocompleteService.current || !placeDetailsService.current) {
      return undefined;
    }
    if (inputValue.length <= 3) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        sessionToken: sessionToken?.current,
        componentRestrictions: { country: "ca" },
        types: ["street_address", "premise"],
      },
      async (results?: readonly GoogleAutocompleteAddressProps[]) => {
        if (active) {
          let newOptions: readonly GoogleAutocompleteAddressProps[] = [];
          if (value) {
            newOptions = [value];
          }
          if (results) {
            const filteredResults = results.filter((d) => d.terms.length < 6);
            const resolvedPostalCodePromises = await Promise.all(
              filteredResults.map(async (option) => ({
                ...option,
                postal: await getPostalCode(option.place_id),
              }))
            );
            const filteredNewOptions = resolvedPostalCodePromises.filter(
              (option) => option.postal !== ""
            );
            newOptions = [...newOptions, ...filteredNewOptions];
          }
          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : option.description.slice(0, option.description.indexOf(",")).trim()
      }
      freeSolo
      open={open}
      onFocus={() => {
        setFocus(true);
        setOpen(true);
      }}
      onBlur={() => {
        setFocus(false);
        setOpen(false);
      }}
      sx={{ mt: 2, mb: 1 }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={async (_: any, newValue: any) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        let street: string = "",
          city: string = "",
          province: string = "",
          country: string = "",
          postal: string = "";
        if (newValue) {
          sessionToken.current = new (
            window as any
          ).google.maps.places.AutocompleteSessionToken();
          const cleanedTerms = newValue.terms.filter(
            (term: any) => !regex.test(term.value)
          );
          if (cleanedTerms.length === 4) {
            street = cleanedTerms[0].value || "";
            city = cleanedTerms[1].value;
            province = getProvinceAbbreviation(cleanedTerms[2].value);
            country = cleanedTerms[3].value;
          } else {
            street = `${cleanedTerms[0].value} ${cleanedTerms[1].value}`;
            city = cleanedTerms[2].value;
            province = getProvinceAbbreviation(cleanedTerms[3].value);
            country = cleanedTerms[4].value;
          }
          postal = newValue.postal || "";
          setAddress({ street, city, province, postal, country });
          setOpen(false);
        } else {
          setAddress(null);
        }
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...(params as any)}
          {...register("street")}
          data-sl={addressType === "business" ? "mask" : ""}
          required
          size={desktop ? "medium" : "small"}
          label={t("street")}
          error={error}
          onAnimationStart={handleAutoFill}
          helperText={helperText}
          fullWidth
        />
      )}
      componentsProps={{
        popper: {
          placement: "top",
          sx: { paddingBottom: "10px" },
        },
      }}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <li {...props} data-sl="mask">
            <Grid container alignItems="center">
              <Grid item>
                <Box sx={{ color: "text.secondary", mr: 2 }}>
                  <FontAwesomeIcon
                    icon={faMapLocationDot}
                    size="lg"
                    color="#4AB5D8"
                  />
                </Box>
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
